

.imagesize{
width: 100%; max-width: 1300px; height: auto; max-height: 500px;
}

.wimage{
    width: 1000px
}

  /* Imports */
  @import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,500&display=swap');

  /* Define variables */
  :root {
    --main-col: #000000;
  }

  .card-title {
    background: none;
  }

  .card {
    border: 2px solid #FFFFFF;
    background-color: #000000;
    border-radius: 10px;
  }

  /* Change selection Color */
  ::selection {
    color: #BCFF00;
    background: var(--main-col);
  }

  .allpage {
    display: flex;
  }

  /* Remove margins and paddings on every element initally */
  * {
    margin: 0px;
    padding: 0px;
  }

  /* .sidebar
{
  height:100vh;
  width:250px;
  background-color:#252627;
} */

  .menu {
    user-select: none;
    display: flex;
    justify-content: space-around;
    width: 250px;
    height: 40px;
    display: flex;
    align-items: center;
    color: #252627;
    background: #BCFF00;

    transition-duration: 0.3s;
  }

  .menu:hover {
    background-color: var(--main-col);
    color: #BCFF00;
    cursor: pointer;
  }




  /* Container for all Pages */


  /* Container for single page */
  .page {
    width: calc(100% - 250px);
    padding: 50px;
    animation: fadein 0.3s 1 ease-out;
  }

  /* Style for permalinks */
  a {
    color: var(--main-col);
  }

  /* Fade in animation */
  @keyframes fadein {
    from {
      transform: translateY(20px);
      opacity: 0;
    }

    to {
      transform: translateY(0px);
    }
  }

  /* Add margin to every element in a page */
  .page * {
    /* margin: 10px; */
    padding-top: 0;
    /* Remove padding on the top side */
  }
  .ali{
    height: 430px !important;
}

  .menu * {

    transform: translateX(0px);
    transition-duration: 0.3s;
  }

  /* Translate the menu text to the right on hover */
  .menu:hover>* {
    transition-duration: 0.3s;
    transform: translateX(20px);
  }

  /* Disable and enable arrow on hover */
  .menu>i {
    opacity: 0;
  }

  /* Regular styles */

  /* Media query for phones */
  @media only screen and (max-width: 600px) {

    /* Adjust styles for smaller screens */
    #SellTech {
      /* For example, you can modify the layout or width of the content */
      width: 100%;
      /* Ensure it takes the full width of the screen */
    }

    #SellTech h1 {
      /* Adjust heading styles for smaller screens */
      font-size: 24px;
      /* Modify font size as needed */
    }

    #SellTech input[type="checkbox"],
    #SellTech img {
      /* Adjust input and image styles for smaller screens */
      max-width: 100%;
      height: auto;
      display: block;
      /* Ensure they occupy full width available */
      margin: 10px 0;
      /* Add some spacing for better visibility */
    }

    /* Additional responsive styles as needed */
  }
/* --------------------------------sell phone----------------- */
@media only screen and (max-width: 600px) {

/* Adjust styles for smaller screens */
#settings {
  /* For example, you can modify the layout or width of the content */
  width: 100%;
  /* Ensure it takes the full width of the screen */
}
.kh{
  width: 50%;
}
#settings h1 {
  /* Adjust heading styles for smaller screens */
  font-size: 24px;
  /* Modify font size as needed */
}

#settings input[type="checkbox"],
#settings img {
  /* Adjust input and image styles for smaller screens */
  max-width: 100%;
  height: auto;
  display: block;
  /* Ensure they occupy full width available */
  margin: 10px 0;
  /* Add some spacing for better visibility */
}

/* Additional responsive styles as needed */
}
/* -------------------------------qcr------------------ */
@media only screen and (max-width: 600px) {

/* Adjust styles for smaller screens */
#home {
  /* For example, you can modify the layout or width of the content */
  width: 100%;
  /* Ensure it takes the full width of the screen */
}
.kh{
  width: 50%;
}
#home h1 {
  /* Adjust heading styles for smaller screens */
  font-size: 24px;
  /* Modify font size as needed */
}

#home input[type="checkbox"],
#home img {
  /* Adjust input and image styles for smaller screens */
  max-width: 100%;
  height: auto;
  display: block;
  /* Ensure they occupy full width available */
  margin: 10px 0;
  /* Add some spacing for better visibility */
}

/* Additional responsive styles as needed */
}
/* ---------------------------game console-------------- */
@media only screen and (max-width: 600px) {

/* Adjust styles for smaller screens */
#about {
  /* For example, you can modify the layout or width of the content */
  width: 100%;
  /* Ensure it takes the full width of the screen */
}
.kh{
  width: 50%;
}
#about h1 {
  /* Adjust heading styles for smaller screens */
  font-size: 24px;
  /* Modify font size as needed */
}

#about input[type="checkbox"],
#about img {
  /* Adjust input and image styles for smaller screens */
  max-width: 100%;
  height: auto;
  display: block;
  /* Ensure they occupy full width available */
  margin: 10px 0;
  /* Add some spacing for better visibility */
}

/* Additional responsive styles as needed */
}
/* --------------------sellTech---------------------------- */
@media only screen and (max-width: 600px) {

/* Adjust styles for smaller screens */
#SellTech {
  /* For example, you can modify the layout or width of the content */
  width: 100%;
  /* Ensure it takes the full width of the screen */
}
.kh{
  width: 50%;
}
#SellTech h1 {
  /* Adjust heading styles for smaller screens */
  font-size: 24px;
  /* Modify font size as needed */
}

#SellTech input[type="checkbox"],
#SellTech img {
  /* Adjust input and image styles for smaller screens */
  max-width: 100%;
  height: auto;
  display: block;
  /* Ensure they occupy full width available */
  margin: 10px 0;
  /* Add some spacing for better visibility */
}

/* Additional responsive styles as needed */
}

/* --------------------------------sCeXHistory----------------- */
@media only screen and (max-width: 600px) {

/* Adjust styles for smaller screens */
#CeXHistory {
  /* For example, you can modify the layout or width of the content */
  width: 100%;
  /* Ensure it takes the full width of the screen */
}
.kh{
  width: 50%;
}
#CeXHistory h1 {
  /* Adjust heading styles for smaller screens */
  font-size: 24px;
  /* Modify font size as needed */
}

#CeXHistory input[type="checkbox"],
#CeXHistory img {
  /* Adjust input and image styles for smaller screens */
  max-width: 100%;
  height: auto;
  display: block;
  /* Ensure they occupy full width available */
  margin: 10px 0;
  /* Add some spacing for better visibility */
}

/* Additional responsive styles as needed */
}
/* --------------------------------QCLPeople----------------- */
@media only screen and (max-width: 600px) {

/* Adjust styles for smaller screens */
#QCLPeople {
  /* For example, you can modify the layout or width of the content */
  width: 100%;
  /* Ensure it takes the full width of the screen */
}
.kh{
  width: 50%;
}
#QCLPeople h1 {
  /* Adjust heading styles for smaller screens */
  font-size: 24px;
  /* Modify font size as needed */
}

#QCLPeople input[type="checkbox"],
#QCLPeople img {
  /* Adjust input and image styles for smaller screens */
  max-width: 100%;
  height: auto;
  display: block;
  /* Ensure they occupy full width available */
  margin: 10px 0;
  /* Add some spacing for better visibility */
}

/* Additional responsive styles as needed */
}
/* --------------------------------PressClippings----------------- */
@media only screen and (max-width: 600px) {

/* Adjust styles for smaller screens */
#PressClippings {
  /* For example, you can modify the layout or width of the content */
  width: 100%;
  /* Ensure it takes the full width of the screen */
}
.kh{
  width: 50%;
}
#PressClippings h1 {
  /* Adjust heading styles for smaller screens */
  font-size: 24px;
  /* Modify font size as needed */
}

#PressClippings input[type="checkbox"],
#PressClippings img {
  /* Adjust input and image styles for smaller screens */
  max-width: 100%;
  height: auto;
  display: block;
  /* Ensure they occupy full width available */
  margin: 10px 0;
  /* Add some spacing for better visibility */
}

/* Additional responsive styles as needed */
}
/* --------------------------------AboutPaul----------------- */
@media only screen and (max-width: 600px) {

/* Adjust styles for smaller screens */
#AboutPaul {
  /* For example, you can modify the layout or width of the content */
  width: 100%;
  /* Ensure it takes the full width of the screen */
}
.kh{
  width: 50%;
}
#AboutPaul h1 {
  /* Adjust heading styles for smaller screens */
  font-size: 24px;
  /* Modify font size as needed */
}

#AboutPaul input[type="checkbox"],
#AboutPaul img {
  /* Adjust input and image styles for smaller screens */
  max-width: 100%;
  height: auto;
  display: block;
  /* Ensure they occupy full width available */
  margin: 10px 0;
  /* Add some spacing for better visibility */
}

/* Additional responsive styles as needed */
}
/* --------------------------------CeXLogos----------------- */
@media only screen and (max-width: 600px) {

/* Adjust styles for smaller screens */
#CeXLogos {
  /* For example, you can modify the layout or width of the content */
  width: 100%;
  /* Ensure it takes the full width of the screen */
}
.kh{
  width: 50%;
}
#CeXLogos h1 {
  /* Adjust heading styles for smaller screens */
  font-size: 24px;
  /* Modify font size as needed */
}
.kl{
  margin-left: auto;
}
#CeXLogos input[type="checkbox"],
#CeXLogos img {
  /* Adjust input and image styles for smaller screens */
  max-width: 100%;
  height: auto;
  display: block;
  /* Ensure they occupy full width available */
  margin: 10px 0;
  /* Add some spacing for better visibility */
}

/* Additional responsive styles as needed */
}
/* --------------------------------GenderPayGap----------------- */
@media only screen and (max-width: 600px) {

/* Adjust styles for smaller screens */
#GenderPayGap {
  /* For example, you can modify the layout or width of the content */
  width: 100%;
  /* Ensure it takes the full width of the screen */
}
.kh{
  width: 50%;
}
#GenderPayGap h1 {
  /* Adjust heading styles for smaller screens */
  font-size: 24px;
  /* Modify font size as needed */
}
.kl{
  margin-left: auto;
}
#GenderPayGap input[type="checkbox"],
#GenderPayGap img {
  /* Adjust input and image styles for smaller screens */
  max-width: 100%;
  height: auto;
  display: block;
  /* Ensure they occupy full width available */
  margin: 10px 0;
  /* Add some spacing for better visibility */
}

/* Additional responsive styles as needed */
}
/* --------------------------------ModernSlaveryStatement----------------- */
@media only screen and (max-width: 600px) {

/* Adjust styles for smaller screens */
#ModernSlaveryStatement {
  /* For example, you can modify the layout or width of the content */
  width: 100%;
  /* Ensure it takes the full width of the screen */
}
.kh{
  width: 50%;
}
#ModernSlaveryStatement h1 {
  /* Adjust heading styles for smaller screens */
  font-size: 24px;
  /* Modify font size as needed */
}
.kl{
  margin-left: auto;
}
#ModernSlaveryStatement input[type="checkbox"],
#ModernSlaveryStatement img {
  /* Adjust input and image styles for smaller screens */
  max-width: 100%;
  height: auto;
  display: block;
  /* Ensure they occupy full width available */
  margin: 10px 0;
  /* Add some spacing for better visibility */
}

/* Additional responsive styles as needed */
}
/* --------------------------------OurCOVID-19Response----------------- */
@media only screen and (max-width: 600px) {

/* Adjust styles for smaller screens */
#OurCOVID-19Response {
  /* For example, you can modify the layout or width of the content */
  width: 100%;
  /* Ensure it takes the full width of the screen */
}
.kh{
  width: 50%;
}
#OurCOVID-19Response h1 {
  /* Adjust heading styles for smaller screens */
  font-size: 24px;
  /* Modify font size as needed */
}
.kl{
  margin-left: auto;
}
#OurCOVID-19Response input[type="checkbox"],
#OurCOVID-19Response img {
  /* Adjust input and image styles for smaller screens */
  max-width: 100%;
  height: auto;
  display: block;
  /* Ensure they occupy full width available */
  margin: 10px 0;
  /* Add some spacing for better visibility */
}

/* Additional responsive styles as needed */
}

/* --------------------------------CounterfeitProductsPolicy----------------- */
@media only screen and (max-width: 600px) {

/* Adjust styles for smaller screens */
#CounterfeitProductsPolicy {
  /* For example, you can modify the layout or width of the content */
  width: 100%;
  /* Ensure it takes the full width of the screen */
}
.kh{
  width: 50%;
}
#CounterfeitProductsPolicy h1 {
  /* Adjust heading styles for smaller screens */
  font-size: 24px;
  /* Modify font size as needed */
}
.kl{
  margin-left: auto;
}
#CounterfeitProductsPolicy input[type="checkbox"],
#CounterfeitProductsPolicy img {
  /* Adjust input and image styles for smaller screens */
  max-width: 100%;
  height: auto;
  display: block;
  /* Ensure they occupy full width available */
  margin: 10px 0;
  /* Add some spacing for better visibility */
}

/* Additional responsive styles as needed */
}

.kl{
  margin-left: 250px;
}
  .menu:hover>i {
    opacity: 1;
  }
  
  /* Translate the arrow logo right on click */
  .menu:active>i {
    margin-left: 10px;
  }

  .paragraph {
    color: #FFFFFF;
  }


  body {
    /*   Add imported font */
    font-family: 'DM Sans', sans-serif;

    /*   Set colors for checkboxes, inputs, etc */
    accent-color: var(--main-col);
  }

  /* Style for website title */
  .title {
    user-select: none;
    /* color: white; */
    padding: 20px;
  }

  .navbar {
    background-color: #bcff00;
  }

  .buy {
    color: #bcff00;
    background-color: #000000;
  }

  .khuram {
    margin-left: 250px !important;
    margin-top: -570px;
  }

  /* ------------------------------- */
  @media (max-width: 576px) {
    .search-box {
        margin-left: 0;
        text-align: center; /* Center-align contents on smaller screens */
    }
   .smcarde {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    width: 300px;
    /* margin-left: 50px; */
}
.tit{
  color: #000000;
font-size: 20px;
}
@media (max-width: 768px) {
    .about-link  {
        position: static; /* Reset position */
        margin-left: auto; /* Set margin-left to auto to push to the right */
        display: block; /* Ensure it's a block-level element */
        text-align: right; /* Align the content to the right */
        
    }
   
    .uk{
      margin-top: -50px; /* Example of setting a negative margin on the top side */
    }
   .ma{
    margin-top: 30px;
   }
}


}

  @media (max-width: 768px) {
    .khuram {
      margin-left: 0 !important;
      margin-top: 0px;
    }

    .allpage {
      margin-top: 60PX;
    }

    .allpage .about-link {
      position: static;
      /* Reset position */
      margin-left: auto;
      /* Set margin-left to auto to push to the right */
      display: block;
      /* Ensure it's a block-level element */
      text-align: right;
      /* Align the content to the right */

    }

    @media (max-width: 576px) {
    .ml-5-sm {
      margin-left: 0 !important;
    }
    .col-md-12.ml-5 {
      margin-left: 0 !important;
    }
    .img-fluid {
      width: 100px; /* Adjust image width for smaller screens */
    }
    .d-flex.kl {
      flex-direction: column; /* Stack buttons vertically on smaller screens */
      align-items: center; /* Center-align buttons */
    }
    .btn {
      margin-bottom: 5px; /* Add margin between buttons */
    }
  }
    .uk {
      margin-top: -50px;
      /* Example of setting a negative margin on the top side */
    }

    .ma {
      margin-top: 30px;
    }
  }

  /* Regular styles */

  /* Media query for phones */
  @media only screen and (max-width: 600px) {
    .image-container {
      /* Adjust styles for smaller screens */
      max-width: 100%;
      /* Optionally, limit the container's width on small screens */
    }


    .allpage {
      display: inline;

    }

    .allpage {
      width: 80%;
      /* Set the width of the container */
      max-width: 1200px;
      /* Optionally, set a maximum width */
      margin: 0 auto;
      /* Center the container horizontally */
    }
 

/* Media query for smaller screens (phones) */
@media screen and (max-width: 767px) {
  .btn {
    width: 100%; /* Take full width on smaller screens */
    font-size: 20px; /* Adjust font size for smaller screens */
    /* Any other adjustments for smaller screens */
  }
}
    .image-container img {
      /* Adjust image styles for smaller screens */
      /* For example, you could remove the inline styles here if needed */
      max-width: 100%;
      height: auto;
    }

    /* Additional responsive styles as needed */
  }
/* Default height for the card */
/* .card {
  height: 380px;
} */

/* Media query for phones (adjust the max-width as needed) */
@media screen and (max-width: 767px) {
  .card {
    height: auto; /* or height: unset; to remove the specified height */
  }
}

