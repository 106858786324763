.coler{
    color: #071A44 !important;
}
.stl{
    display: flex; flex-direction: column; justify-content: flex-start;
}

.bg-body-tertiary {
color: #071A44;border-top: #071A44 solid; border-bottom: #071A44 solid;
}

  


/* ------------------------------------Local---------------------- */
@keyframes fade{
    from{
      opacity:0.4;
    }
    to{
      opacity:1;
    }
  }
  
 
  
  #slider{
    margin:0 auto;
    width:80%;
    overflow:hidden;
  }
  
  .slides{
    overflow:hidden;
    animation-name:fade;
    animation-duration:1s;
    display:none;
  }
  
 
 
  
  #dot{
    margin:0 auto;
    text-align:center;
  }
  /* .dot{
    display:inline-block;
    border-radius:50%;
    background:#d3d3d3;
    padding:8px;
    margin:10px 5px;
  }
  
  .active{
    background:black;
  } */
  
  @media (max-width:567px){
    #slider{
      width:100%;
  
    }
  }
  
  #heading{
    display:block;
    text-align:center;
    font-size:2em;
    margin:10px 0px;
  
  }
/* Default styles for controls */
.carousel-controls {
  position: relative;
  
  /* Other styles as needed */
}

.carousel-control-prev {
    /* margin-top: 20px; */
    margin-left: 1300px;
  }
.carousel-control-prev,
.carousel-control-next {
  /* Default styles for both prev and next controls */
  width: 5%;
  height: 10vh;
}

/* Media query for phones */
@media (max-width: 576px) {
  .carousel-control-prev,
  .carousel-control-next {
    width: 10%;
    height: 8vh;
    margin: 0;
  }
  
  /* Adjust positioning for prev control */
  .carousel-control-prev {
    margin-top: 20px;
    margin-left: 20px;
  }
  .carousel-control-prev {
    /* margin-top: 20px; */
    margin-left: 0px;
  }
  
  /* Adjust positioning for next control */
  .carousel-control-next {
    margin-top: 20px;
    margin-right: 20px;
  }
}

/* Default styles for larger screens */
.card-deck {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 120%;
}

/* Media query for smaller screens (e.g., phones) */
@media (max-width: 768px) {
    .card-deck {
        margin-right: 0;
        margin-left: 0;
        flex-direction: column; /* Stack cards vertically */
        align-items: center; /* Center-align cards */
        width: 50%;
    }
    .card {
        margin-bottom: 20px; /* Add some space between cards */
    }

}

.search-box {
    height: 7vh;
    width: 90%; /* Adjust width for smaller screens */
    max-width: 700px; /* Set a maximum width */
    position: relative;
    display: flex;
    margin: auto;
}

/* Media query for smaller screens (e.g., phones) */
@media (max-width: 576px) {
    .search-box {
        width: 90%; /* Adjust width for smaller screens */
        height: auto; /* Allow height to adjust based on content */
        max-width: none; /* Remove maximum width */
        padding: 0 10px; /* Add padding as needed */
        justify-content: center; /* Center content horizontally */
        align-items: center; /* Center content vertically */
    }
}

.row{
margin-right: 0px;
}

.search-input {
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  padding: 15px 45px 15px 15px;
  background-color: #eaeaeb;
  color: #6c6c6c;
  border-radius: 6px;
  border:none;
  transition: all .4s;
}

.search-input:focus {
  border:none;
  outline:none;
  box-shadow: 0 1px 12px #b8c6db;
  -moz-box-shadow: 0 1px 12px #b8c6db;
  -webkit-box-shadow: 0 1px 12px #b8c6db;
}

.search-btn {
  background-color: transparent;
  font-size: 18px;
  padding: 6px 9px;
  margin-right:-45px;
  border:none;
  color: #6c6c6c;
  transition: all .4s;
  z-index: 10;
}

.search-btn:hover {
  transform: scale(1.2);
  cursor: pointer;
  color: black;
}
.col p {
    margin: 0; /* or padding: 0; */
  }
.search-btn:focus {
  outline:none;
  color:black;
}
.search-input {
    padding-left: 50px; /* Adjust the padding as needed */
    margin-left: 100px;
}

    .navbar{
        background-color: #bcff00;
    }
    .navbar-toggler-icon {
    background-color: #000000; /* Set the color to black */
  }
  .buy{
    color: #bcff00;
    background-color: #000000;
  }
  .main{
    background-color: #bcff00;
  }
 
  .bu{
    background-color: #bcff00;
border: 2px solid black;
  }
  
  .kh{
    background-color: #000000!important;
    border: 2px solid #bcff00;
    color: #bcff00;
    border-radius: 20px;
  }
  a {
  text-decoration: none !important; /* Remove underline */
}

.search-box {
            display: flex;
            align-items: center;
        }

        .search-input {
            flex: 1;
            margin-left: 10px; /* Adjust the spacing between icon and input */
        }
.about-link h5 {
  text-decoration: none !important;
  color: #000; /* Set initial text color */
  transition: background-color 0.3s ease; /* Smooth transition for background color change */
}
.about-linkk h5 {
  color: #000; /* Set initial text color */
  transition: background-color 0.3s ease; /* Smooth transition for background color change */
}

.smcarde{
  width: 1000px;
}
.u{
  width: 100%;
}
.bordered-image {
   border-radius: 5px ;
        border: 3px solid black; /* Adjust the size and color as needed */
        padding: 10px; /* Optional: Adds padding inside the border */
    }
.about-linkk:hover h5 {
  color: #bcff00; /* Change background color on hover */
}
/* Adjust search box on smaller screens (phones) */
@media (max-width: 576px) {
    .search-box {
        margin-left: 0;
        text-align: center; /* Center-align contents on smaller screens */
    }
   .smcarde {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    width: 300px;
    /* margin-left: 50px; */
}
.tit{
  color: #ffffff;
font-size: 20px;
}
@media (max-width: 768px) {
    .about-link  {
        position: static; /* Reset position */
        margin-left: auto; /* Set margin-left to auto to push to the right */
        display: block; /* Ensure it's a block-level element */
        text-align: right; /* Align the content to the right */
        
    }
   
    .uk{
      margin-top: -50px; /* Example of setting a negative margin on the top side */
    }
   .ma{
    margin-top: 30px;
   }
}


}
.hh{
  width: 130%;
}
@media (max-width: 767px) {
    .gx-5 {
      flex-wrap: wrap; /* Allow items to wrap onto new lines */
      width: 100%; /* Adjust width to fit the screen */
    }
    .hh{
  width: 100%;
}
    .col {
      flex: 0 0 100%; /* Make each column take up the full width */
      max-width: 50%; /* Ensure columns don't exceed the screen width */
      margin-bottom: 20px; /* Add some space between columns */
    }
  }