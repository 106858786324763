.main {
  background-color: #071A44 !important;
}
.smatcard {
  font-size: 15px;
  font-weight: 400;
  color: #0f0f0f ;
}



.kh {
  width: 130px;
}

.imgslider {
  border: 1px solid #000;
  border-radius: 5px;
}

.bul {
  justify-content: center;
  max-width: 105%;
  height: 13vh;
  display: block;
}

@media (max-width: 768px) {
  /* Styles for smaller screens */
  .bul {
    justify-content: center;
    max-width: 105%;
    height: 50vh;
    display: block;
  }
}

/* ------------------------local */

/* Default styles for larger screens */
.card-deck {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 120%;
}

/* Media query for smaller screens (e.g., phones) */
@media (max-width: 768px) {
  .card-deck {
    margin-right: 0;
    margin-left: 0;
    flex-direction: column;
    /* Stack cards vertically */
    align-items: center;
    /* Center-align cards */
    width: 50%;
  }

  .card {
    margin-bottom: 20px;
    /* Add some space between cards */
  }
}

.search-box {
  height: 7vh;
  width: 90%;
  /* Adjust width for smaller screens */
  max-width: 700px;
  /* Set a maximum width */
  position: relative;
  display: flex;
  margin: auto;
}

/* Media query for smaller screens (e.g., phones) */
@media (max-width: 576px) {
  .search-box {
    width: 90%;
    /* Adjust width for smaller screens */
    height: auto;
    /* Allow height to adjust based on content */
    max-width: none;
    /* Remove maximum width */
    padding: 0 10px;
    /* Add padding as needed */
    justify-content: center;
    /* Center content horizontally */
    align-items: center;
    /* Center content vertically */
  }
}

.search-btn {
  background-color: transparent;
  font-size: 18px;
  padding: 6px 9px;
  margin-right: -45px;
  border: none;
  color: #6c6c6c;
  transition: all 0.4s;
  z-index: 10;
}

.search-btn:hover {
  transform: scale(1.2);
  cursor: pointer;
  color: black;
}

.col p {
  margin: 0;
  /* or padding: 0; */
}

.search-btn:focus {
  outline: none;
  color: black;
}

.search-input {
  padding-left: 50px;
  /* Adjust the padding as needed */
  margin-left: 100px;
}

#imgstyle {
  width: auto;
  height: auto;
}

.navbar-toggler-icon {
  background-color: #000000;
  /* Set the color to black */
}
a {
  text-decoration: none !important;
  /* Remove underline */
}

.search-box {
  display: flex;
  align-items: center;
}

.search-input {
  flex: 1;
  margin-left: 10px;
  /* Adjust the spacing between icon and input */
}

.about-link h5 {
  text-decoration: none !important;
  color: #000;
  /* Set initial text color */
  transition: background-color 0.3s ease;
  /* Smooth transition for background color change */
}

.about-linkk h5 {
  color: #000;
  /* Set initial text color */
  transition: background-color 0.3s ease;
  /* Smooth transition for background color change */
}

.smcarde {
  width: 1000px;
}

.u {
  width: 100%;
}

.bordered-image {
  border-radius: 5px;
  border: 3px solid black;
  /* Adjust the size and color as needed */
  padding: 10px;
  /* Optional: Adds padding inside the border */
}



/* Adjust search box on smaller screens (phones) */
@media (max-width: 576px) {
  .search-box {
    margin-left: 0;
    text-align: center;
    /* Center-align contents on smaller screens */
  }

  .smcarde {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    width: 300px;
    /* margin-left: 50px; */
  }

  .tit {
    color: #ffffff;
    font-size: 20px;
  }

  @media (max-width: 768px) {
    .about-link {
      position: static;
      /* Reset position */
      margin-left: auto;
      /* Set margin-left to auto to push to the right */
      display: block;
      /* Ensure it's a block-level element */
      text-align: right;
      /* Align the content to the right */
    }

    .uk {
      margin-top: -50px;
      /* Example of setting a negative margin on the top side */
    }

    .ma {
      margin-top: 30px;
    }
  }
}

.hh {
  width: 130%;
}

@media (max-width: 767px) {
  .gx-5 {
    flex-wrap: wrap;
    /* Allow items to wrap onto new lines */
    width: 100%;
    /* Adjust width to fit the screen */
  }

  .hh {
    width: 100%;
  }

  .col {
    flex: 0 0 100%;
    /* Make each column take up the full width */
    max-width: 50%;
    /* Ensure columns don't exceed the screen width */
    margin-bottom: 20px;
    /* Add some space between columns */
  }
}
.search-input {
  width: 200% !important;
}
.bu {
  width: 190%;
  background-color: #ffffff !important;
  border: none;
  color: #000;
}
.itemtext {
  font-size: 4vw;
}
.smatcard {
  /* Limit text to two lines */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}