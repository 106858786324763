.lor{
    background-color: rgb(255, 255, 255);
    border-radius: 30px; /* Adjust this value to change the roundness of the corners */
    width: 708%;
    /* margin-left: 1px; */
}

.icon-shape {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
}

.icon-sm {
    width: 2rem;
    height: 2rem;
    

}
.tt{
    border-color: linear-gradient(red, green) !important;
    border-radius: 10px;
}