/* UserSidebar.css */
.user-sidebar {
  margin: 50px;
  width: 278px;
  background-color: white; /* Bootstrap light background color */
  color: black; /* Bootstrap dark text color */
  padding-top: 20px;
  border-radius: 24px;
  border: solid lightgray 2px;
}

.sidebar-header {
  /* text-align: center; */
  padding-left: 20px;
  margin-bottom: 20px;
}

.user-menu-item {
  padding: 10px;
  padding-left: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bolder;
  transition: background-color 0.3s;
}

.user-menu-item:hover {
  background-color: #6aff00; /* Bootstrap danger color on hover */
}

.user-menu-item span {
  margin-left: 10px;
}

.user-menu-item.large {
  font-size: 16px;
}

.user-menu-item.large svg {
  font-size: 20px;
  margin-right: 10px;
}
